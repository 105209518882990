<template>
  <b-carousel
    id="carousel-interval"
    controls
    indicators
    :interval="25000"
  >
    <b-carousel-slide
      v-for="spk in speakers"
      :key="spk.id"
    >
      <div class="media-list">
        <b-media vertical-align="top">
          <template #aside>
            <b-img
              v-if="spk.photo != null"
              :src="'https://apisnno.ovnicloud.page/storage/image/'+spk.photo"
              blank-color="#ccc"
              width="60"
              alt="placeholder"
            />
            <b-avatar
              v-if="spk.photo == null"
              size="60"
              :src="require('@/assets/images/portrait/small/avatar-s-13.jpg')"
              blank-color="#ccc"
            />
          </template>
          <h4
            class="media-heading"
            style="text-align: left !important"
          >
            {{ spk.name_badge }}
          </h4>
          <b-card-text
            class="mb-0 miniText"
            style="text-align: left !important"
          >
            {{ spk.mini_curriculo }}
          </b-card-text>
          <b-card-text
            v-if="spk.instagram != null"
            class="mb-0 miniText"
            style="text-align: left !important"
          >
            Instagram: @{{ spk.instagram }}
          </b-card-text>
        </b-media>
      </div>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import { codeCarouselInterval } from './code'

export default {
  name: 'CarouselInterval',
  components: {
    BCarousel,
    BCarouselSlide,
  },
  props: {
    speakers: Array
  },
  data() {
    return {
      codeCarouselInterval,
      button_expansion: true
    }
  },
  methods: {
    expandDiv() {}
  }
}
</script>
<style scoped>
.photoPosition{
  padding-bottom: 25px;
}
.namePosition{
  padding-top: 25px;
}
.miniText{
  color: black;
}
.carouselIntervalCss {
  text-align: left;
}
.spk{
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

</style>
